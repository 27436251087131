
      @use '_variables.scss' as *;
      @use '_mixins.scss' as *;
    
html,
body {
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  font-family: Open Sans !important;
}

body {
  overflow-x: hidden;
  /* Hide scrollbar for Chrome, Safari and Opera */
  *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  --width: 25vw;
}

// img {
//   pointer-events: none;
// }

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

// lex bot
.lex-web-ui-iframe {
  width: clamp(0px, 20vw, 414px) !important;
  height: clamp(0px, 50vh, 667px) !important;
}

.lex-web-ui-iframe--minimize {
  min-width: 80px !important;
  width: 80px !important;
}

//@font-face {
//  font-family: 'Inter';
//  /* src: url('../public/fonts/TruenoRg.otf'); */
//  src: url('../public/fonts/inter/Inter-Regular.ttf');
//  font-weight: 400;
//  font-display: auto;
//  font-style: normal;
//}
